import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import $ from 'jquery';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import generals from '../../../../../../css/Generals.module.css';
import { cancelFactura } from '../../../../../api/facturas';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import Loading from '../../../../../common/Loading';
import classes from '../css/FacturaModal.module.css';
import FacturaModalFormContainer from './FacturaModalFormContainer';

const FacturaModalInfo = ({ setModalInfo, modalInfo, setRefetch }) => {
	const [cancel, setCancel] = useState(false);
	const [cancelMotive, setCancelMotive] = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState({ state: false, msg: '' });
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [facturaModal, setFacturaModal] = useState(false);
	const TOKEN = useSelector((state) => state.auth.tokenId);

	const inputLabelStyles = {
		top: 14,
		left: 12.5,
		color: '#57585AD2',
		opacity: 0.4,
	};

	const motives = [
		'01 - Comprobante emitido con errores con relación: Factura tiene error en precio, cantidad, descripción pero el receptor es correcto.',
		'02 - Comprobante emitido con errores sin relación: La factura se emitió al receptor incorrecto.',
		'03 - No se llevó a cabo la operación: No se llevó a cabo el servicio o venta correspondiente a la factura.',
		'04 - Operación nominativa relacionada en la factura global: Se cancelará una parte de la factura de público en general.',
	];

	const handleChange = (e) => {
		setCancelMotive(e.target.value);
	};

	const handleClose = () => {
		const dialogOpen = document.querySelectorAll('ul.MuiList-root');
		if (dialogOpen?.length <= 0) {
			$('body').css('overflow', '');
			setModalInfo(false);
			setMessage({
				state: false,
				msg: '',
			});
		} else {
			$('body').css('overflow', 'auto');
		}
	};

	const handleCancelFactura = async () => {
		setLoading(true);
		if (!cancelMotive) {
			setIsSubmitted(true);
			setLoading(false);
			return;
		}
		try {
			const response = await cancelFactura(
				TOKEN,
				modalInfo.factura.idfactura,
				cancelMotive
			);
			if (response.status === 200) {
				setRefetch((prevState) => !prevState);
				setMessage({
					state: 'success',
					msg: 'La cancelación de tu factura ha sido solicitada con éxito.',
				});
			} else {
				setMessage({
					state: 'error',
					msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
				});
			}
		} catch (error) {
			console.error(error);
			setMessage({
				state: 'error',
				msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={classes.modal_overlay}>
			{!facturaModal && (
				<ClickOutsideReact onClickOutside={handleClose}>
					<div className={classes.modal_container}>
						<button
							className={classes.icon_close_wrapper}
							onClick={handleClose}
						>
							<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
						</button>
						<h1 className={classes.modal_title}>
							{cancel ? 'Cancelar factura' : 'Factura'}
						</h1>
						<h2 className={classes.modal_subtitle}>
							{cancel ? 'Solicitud de cancelación' : 'Desglose de información'}
						</h2>
						{loading &&
							<section className='flex items-center h-[300px]'>
								<Loading loading={loading} />
							</section>
						}
						{!loading && <section className='w-[90%]'>
							<div className={classes.modal_info_container}>
								{modalInfo.fields.length > 0 && !cancel && (
									<div className={classes.modal_info_element_container}>
										{modalInfo.fields.map((x) => (
											<button
												key={x.field}
												className={classes.modal_info_element_wrapper}
											>
												<h1 className={classes.modal_info_element_title}>
													{x.field}
												</h1>
												<h1 className={classes.modal_info_element_subtitle}>
													{x.value || '-'}
												</h1>
											</button>
										))}
									</div>
								)}
								{!message.state && cancel && (
									<div className='flex-col'>
										<h1 className={`${generals.modal_success_text}`}>
											Elige tu motivo de cancelación
										</h1>
										<FormControl sx={{ width: 300 }}>
											<InputLabel
												htmlFor='cancel-motive-dropdown'
												sx={inputLabelStyles}
											>
												Motivo de cancelación
											</InputLabel>
											<Select
												id='cancel-motive-dropdown'
												className={
													isSubmitted && !cancelMotive
														? `${generals.select_input} ${generals.select_input_error}`
														: generals.select_input
												}
												value={cancelMotive}
												onChange={handleChange}
											>
												{motives.map((motive) => (
													<MenuItem key={motive} value={motive}>
														{motive}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>
								)}
								{message.state && (
									<div className={classes.modal_info_loading_container}>
										<p className={`mt-5 ${generals.modal_success_text}`}>
											{message.msg}
										</p>
									</div>
								)}
							</div>
							{cancel && (
								<div className={classes.modal_wrapper_button}>
									{!message.state && (
										<button
											className={classes.modal_button}
											onClick={handleCancelFactura}
										>
											Cancelar factura
										</button>
									)}
									<button
										className={`${classes.modal_button} ${classes.white}`}
										onClick={() => {
											setCancel(false);
											setMessage({ state: false, msg: '' });
											if (message.state) handleClose();
										}}
									>
										{message.state ? 'Cerrar' : 'Volver'}
									</button>
								</div>
							)}
							{!cancel &&
								(modalInfo.factura.estatus === 'emitida' || modalInfo.factura.estatus === 'cancelación emitida') && (
									<div className={classes.modal_wrapper_button}>
										<button
											className={classes.modal_button}
											onClick={modalInfo.retrieveFiles}
										>
											{modalInfo.factura.estatus === 'emitida'
												? 'Descargar factura'
												: 'Descaragar acuse de cancelación'}
										</button>
										{modalInfo.factura.estatus === 'emitida' && (
											<button
												className={`${classes.modal_button} ${classes.white}`}
												onClick={() => setCancel(true)}
											>
												Cancelar factura
											</button>
										)}
									</div>
								)}
							{modalInfo.factura.estatus === 'solicitada' && !cancel && (
								<div className={classes.modal_wrapper_button}>
									<button
										className={classes.modal_button}
										onClick={() => {
											setFacturaModal(true);
										}}
									>
										Editar solicitud
									</button>
								</div>
							)}
						</section>}
					</div>
				</ClickOutsideReact>
			)}
			{facturaModal && (
				<FacturaModalFormContainer
					selectedContact={modalInfo.factura}
					setContactModal={setModalInfo}
					setRefetch={setRefetch}
				/>
			)}
		</div>
	);
};
export default FacturaModalInfo;
