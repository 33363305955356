import { default as React, useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { FaApple } from 'react-icons/fa';
import { IoLogoAndroid } from 'react-icons/io';
import { TbMessage } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import QR from '../../../../../assets/images/CibercontaMEQR.png';
import timeoutIcon from '../../../../../assets/images/dashboard/clock.png';
import dashboardIcon from '../../../../../assets/images/dashboard/dashboard.png';
import declarationsIcon from '../../../../../assets/images/dashboard/invoice.png';
import completeProfileIcon from '../../../../../assets/images/dashboard/kyc.png';
import facturasIcon from '../../../../../assets/images/dashboard/receipt.png';
import suscribeIcon from '../../../../../assets/images/dashboard/register.png';
import userIcon from '../../../../../assets/images/dashboard/user.png';
import walletIcon from '../../../../../assets/images/dashboard/wallet.png';
import emptyBox from '../../../../../assets/images/messages/box.png';
import generals from '../../../../../css/Generals.module.css';
import text from '../../../../../css/Text.module.css';
import { profileActions } from '../../../../../store/profile';
import { formatMexicoTz } from '../../../../../utils/format';
import { getAllNotifications } from '../../../../api/notifications';
import Loading from '../../../../common/Loading';
import styles from './Notifications.module.css';
import classes from './Profile.module.css';

const Profile = () => {
	const dispatch = useDispatch();
	const setProfileData = (data) => {
		dispatch(profileActions.adduserprofiledata(data));
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const [notifications, setNotifications] = useState([]);
	const [loading, setLoading] = useState(false);

	const appleStoreLink =
		'https://apps.apple.com/us/app/ciberconta/id6462903867';
	const googlePlayLink =
		'https://play.google.com/store/apps/details?id=com.nainkhaskheli.Ciberconta';

	const {
		userFullName,
		userEfirma,
		userCIEC,
		userKEYFile,
		userCERFile,
		userAnualPlan,
		userSubscription,
		userZipCode,
		userRegimenFiscal
	} = useSelector((state) => state.clientStatus);

	useEffect(() => {
		setLoading(true);
		const fetchNotifications = async () => {
			try {
				const notifications = await getAllNotifications(
					TOKEN,
					'client',
					USERID
				);
				setNotifications(notifications);
				setLoading(false);
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		};
		fetchNotifications();
	}, []);

	const getIcon = (type) => {
		switch (type) {
			case 'Tienes una declaración pendiente por aprobar.':
				return userIcon;
			case 'Declaración previa por pagar':
			case 'Tienes un pago de impuestos por realizar':
				return walletIcon;
			case 'Hoy vence tu línea de captura':
			case 'Mañana vence tu línea de captura':
				return timeoutIcon;
			default:
				return userIcon;
		}
	};

	const profileComplete = userZipCode && userRegimenFiscal && userEfirma && userCIEC && userKEYFile && userCERFile;
	const planComplete = userSubscription || userAnualPlan;

	return (
		<div>
			<div className={classes.welcome_wrapper}>
				<h2 className='md:text-[44px] text-[28px] mb-3 font-bold'>
					Hola 👋, {userFullName || ''}
				</h2>
				<h3 className='md:text-[24px] text-[20px] mb-3 font-bold'>
					¡Bienvenid@ a tu portal de ciberconta!
				</h3>
			</div>
			{profileComplete && planComplete && (
				<>
					<h3 className={classes.main_title}>Mi Contabilidad</h3>
					<div className={classes.main_grid}>
						<div onClick={() => setProfileData('Declaraciones')} className={`${classes.main_wrapper} col-12 `}>
							<h3 className={classes.main_card_title}>Declaraciones</h3>
							<div className={classes.main_card_wrapper}>
								<div className={classes.main_card_img_wrapper}>
									<img
										src={declarationsIcon}
										className={classes.main_card_img}
										alt='Declaraciones'
									/>
								</div>
								<h2 className={classes.main_card_subtitle}>
									Revisa tus adeudos fiscales pendientes por aprobar y pagar
								</h2>
							</div>
						</div>
						<div className={classes.main_wrapper} onClick={() => setProfileData('Facturas')}>
							<h3 className={classes.main_card_title}>Facturas</h3>
							<div className={classes.main_card_wrapper}>
								<div className={classes.main_card_img_wrapper}>
									<img
										src={facturasIcon}
										className={classes.main_card_img}
										alt='Facturas'
									/>
								</div>
								<h2 className={classes.main_card_subtitle}>
									Solicita facturas a tus clientes y revisa tu historial
								</h2>
							</div>
						</div>
						<div className={classes.main_wrapper} onClick={() => setProfileData('Numeros')}>
							<h3 className={classes.main_card_title}>Mis números</h3>
							<div className={classes.main_card_wrapper}>
								<div className={classes.main_card_img_wrapper}>
									<img
										src={dashboardIcon}
										className={classes.main_card_img}
										alt='Números'
									/>
								</div>
								<h2 className={classes.main_card_subtitle}>
									Visualiza tus finanzas con estadísticas detalladas.
								</h2>
							</div>
						</div>
					</div>
					<div className='hidden md:block'>
						<h3 className={classes.main_title}>Mis Notificaciones</h3>
						<div className={classes.main_grid}>
							<div className={styles.notifications_wrapper}>
								{!loading &&
									notifications?.length > 0 &&
									notifications.map((notification) => {
										return (
											<div
												key={notification.id}
												className={
													notification.viewed
														? `mx-[15px] mb-[7px] ${styles.notifications_item_viewed}`
														: `mx-[15px] mb-[7px] ${styles.notifications_item}`
												}
											>
												<div className={`${styles.notifications_cta_wrapper}`}>
													<button
														onClick={() => setProfileData('Declaraciones')}
														className={`${generals.button_white} ${styles.button_white_no_border}`}
													>
														Ir a mis declaraciones &rarr;
													</button>
												</div>
												<div
													className={`${styles.notifications_item_icon_wrapper}`}
												>
													<img
														className={`${styles.notifications_item_icon_img}`}
														src={getIcon(notification.title)}
														alt=''
													/>
												</div>
												<div
													className={`${styles.notifications_item_body_wrapper}`}
												>
													<div
														className={`${styles.notifications_item_body_header}`}
													>
														<p
															className={`${styles.notifications_item_body_title}`}
														>
															{notification.title}
														</p>
														<p
															className={`${styles.notifications_item_body_date}`}
														>
															{formatMexicoTz(notification.createdAt)}
														</p>
													</div>
													<p
														className={`${styles.notifications_item_body_text}`}
													>
														{notification.description}
													</p>
												</div>
												{notification.viewed && (
													<p
														className={`${styles.notifications_item_viewed_text}`}
													>
														Leída
													</p>
												)}
											</div>
										);
									})}

								{!loading && notifications?.length === 0 && (
									<div className={styles.notifications_body_empty}>
										<img
											className={styles.notifications_icon_box}
											src={emptyBox}
											alt='Sin notificaciones'
										/>
										<h1 className={text.text_title_blue_1}>¡Bandeja vacía!</h1>
										<div className={classes.text_description_wrapper}>
											<p className={text.text_description_black_1}>
												No tienes notificaciones por el momento.
											</p>
										</div>
									</div>
								)}
								{!loading && !notifications && (
									<div className={styles.notifications_body_empty}>
										<img
											className={styles.notifications_icon_box}
											src={emptyBox}
											alt='Error'
										/>
										<h1 className={text.text_title_blue_1}>Lo sentimos.</h1>
										<div className={classes.text_description_wrapper}>
											<p className={text.text_description_black_1}>
												Ha ocurrido un error, favor de contactar a un agente de
												soporte o intentar más tarde.
											</p>
										</div>
									</div>
								)}
								{loading && (
									<div className={styles.loading_container}>
										<Loading loading={loading} />
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			)}
			{(!profileComplete || !planComplete) && (
				<>
					<h3 className={`${classes.main_title} ${classes.main_title_profile}`}>
						<div className={classes.main_number_wrapper}>1</div>
						<button
							className={classes.main_title_button}
							onClick={() => setProfileData('Información')}
						>
							Completa tu perfil
						</button>
					</h3>
					<div className={classes.main_grid}>
						<div className={classes.main_wrapper_profile}>
							<div className={classes.main_card_img_wrapper_profile}>
								<img
									src={completeProfileIcon}
									className={classes.main_card_img}
									alt='Completa tu perfil'
								/>
							</div>
							<div className={classes.main_card_wrapper_profile}>
								<h2 className={classes.main_card_subtitle}>
									{profileComplete
										? '¡Enhorabuena! Tu perfil está completo.'
										: 'Ayúdanos a saber un poco más sobre ti.'}
								</h2>
								<h3 className={classes.main_card_text}>
									{profileComplete
										? 'Último paso: ahora contrata un plan Ciberconta para empezar a disfrutar de nuestros servicios. Contacta un asesor para mayor información.'
										: 'Aún te falta llenar tu perfil. Si tienes dudas sobre cómo llenar tu información escríbenos al chat, un asesor te ayudará a llenar todos los campos.'}
								</h3>
								<div className={classes.main_title_button_wrapper}>
									<button
										onClick={() => setProfileData('Información')}
										className={`${generals.button_blue} ${classes.button_blue}`}
									>
										{profileComplete
											? 'Revisar mis datos'
											: 'Completar mi perfil'}{' '}
										<BsArrowRight className='text-[25px] hidden md:block' />
									</button>
									<button
										className={`${generals.button_white} ${classes.button_white}`}
										onClick={() => window.Intercom('show')}
									>
										<TbMessage
											className='text-[25px] mb-[5px] hidden md:block'
											color='#4285f4'
										/>{' '}
										Contactar un asesor
									</button>
								</div>
							</div>
						</div>
					</div>

					<h3 className={`${classes.main_title} ${classes.main_title_profile}`}>
						<div className={classes.main_number_wrapper}>2</div>
						<button
							className={classes.main_title_button}
							onClick={() => setProfileData('Mi')}
						>
							Contrata tu plan Ciberconta
						</button>
					</h3>
					<div className={classes.main_grid}>
						<div className={classes.main_wrapper_profile}>
							<div className={classes.main_card_img_wrapper_profile}>
								<img
									src={suscribeIcon}
									className={classes.main_card_img}
									alt='Contrata un plan'
								/>
							</div>
							<div className={classes.main_card_wrapper_profile}>
								<h2 className={classes.main_card_subtitle}>
									{planComplete
										? 'Ya eres parte de Ciberconta.'
										: 'Elige la opción que más se acomode a tus necesidades.'}
								</h2>
								<h3 className={classes.main_card_text}>
									{planComplete
										? 'Para poder llevar tu contabilidad, necesitamos saber un poco más sobre ti. Ayúdanos a completar tu perfil.'
										: 'Tenemos un plan para ti, desde suscripciones mensuales y anuales hasta planes de rescate para ponerte al corriente con tus declaraciones atrasadas. Olvídate de los líos fiscales y abraza la tranquilidad financiera.'}
								</h3>
								<button
									onClick={() => setProfileData('Mi')}
									className={`${generals.button_blue} ${classes.button_blue}`}
								>
									{planComplete ? 'Administrar mi plan' : 'Contratar un plan'}{' '}
									<BsArrowRight className='text-[25px] hidden md:block' />
								</button>
							</div>
						</div>
					</div>
				</>
			)}
			<>
				<h3 className={`${classes.main_title} ${classes.main_title_profile}`}>
					{!(profileComplete && planComplete) && (
						<div className={classes.main_number_wrapper}>
							<span>3</span>
						</div>
					)}
					<button
						className={classes.main_title_button}
						onClick={() => window.open(appleStoreLink)}
					>
						Descarga nuestra app
					</button>
				</h3>
				<div className={classes.main_grid}>
					<div className={classes.main_wrapper_profile}>
						<div className={classes.main_card_img_wrapper_profile}>
							<img
								src={QR}
								className={classes.main_card_img}
								alt='Descarga nuestra app'
							/>
						</div>
						<div className={classes.main_card_wrapper_profile}>
							<h2 className={classes.main_card_subtitle}>
								Tu contabilidad en la palma de la mano.
							</h2>
							<h3 className={classes.main_card_text}>
								Lleva a Ciberconta en tu bolsillo: solicita facturas y maneja tu
								historial de declaraciones de manera fácil y segura desde tu
								celular.
							</h3>
							<div className={classes.main_title_button_wrapper}>
								<button
									onClick={() => window.open(appleStoreLink)}
									className={`${generals.button_white} ${classes.button_white}`}
								>
									<FaApple className='text-[35px] mb-[5px]' color='#4285f4' />{' '}
									Ciberconta iOS
								</button>
								<button
									onClick={() => window.open(googlePlayLink)}
									className={`${generals.button_white} ${classes.button_white}`}
								>
									<IoLogoAndroid
										className='text-[35px] mb-[5px]'
										color='#4285f4'
									/>{' '}
									Ciberconta Android
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		</div>
	);
};

export default Profile;
