import React from 'react';
import Loading from '../../../../../common/Loading';
import classes from '../css/Facturas.module.css';
import FacturaItem from './FacturaItem';

const FacturaContainer = ({
	loading,
	error,
	facturas,
	setModalInfo,
	setModalClone,
	setModalConfirm
}) => {
	return (
		<div>
			{!loading && !error.state && facturas.length === 0 && (
				<section className={classes.loading_container}>
					<h1>No se han encontrado registros.</h1>
				</section>
			)}
			{!loading && error.state && (
				<section className={classes.loading_container}>
					<h1>{error.msg}</h1>
				</section>
			)}
			{loading && !error.state && (
				<section className={classes.loading_container}>
					<Loading loading={loading} />
				</section>
			)}
			{!loading && !error.state && facturas.length > 0 && (
				<div className={classes.factura_container}>
					{facturas.map((fac) => (
						<FacturaItem
							key={fac.idfactura}
							factura={fac}
							setModalInfo={setModalInfo}
							setModalClone={setModalClone}
							setModalConfirm={setModalConfirm}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default FacturaContainer;
