import CloseIcon from '@mui/icons-material/Close';
import $ from 'jquery';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import generals from '../../../../../../css/Generals.module.css';
import { cloneFactura } from '../../../../../api/facturas';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import Loading from '../../../../../common/Loading';
import classes from '../css/FacturaModal.module.css';

const FacturaModalClone = ({ setModalClone, modalClone, setRefetch }) => {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState({
		state: 'initial',
		title:
			'Esta función es ideal para facturas idénticas que se realizan al mismo cliente de manera periódica.',
		msg: 'Podrás automatizar el llenado de datos "clonando" los mismos de esta factura emitida. Da clic en continuar para corroborar la información y realizar la solicitud.',
	});
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	const handleClose = () => {
		$('body').css('overflow', 'auto');
		setModalClone(false);
		setMessage({
			state: false,
			msg: '',
		});
	};

	const handleCloneFactura = async () => {
		setLoading(true);
		try {
			const response = await cloneFactura(TOKEN, USERID, modalClone.factura);
			if (response.status === 200) {
				setRefetch((prevState) => !prevState);
				setMessage({
					state: 'success',
					title: '¡Clonación exitosa!',
					msg: 'La factura ha sido solicitada.',
				});
			} else {
				setMessage({
					state: 'error',
					msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
				});
			}
		} catch (error) {
			console.error(error);
			setMessage({
				state: 'error',
				msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={classes.modal_overlay}>
			{modalClone && (
				<ClickOutsideReact onClickOutside={handleClose}>
					<div className={`${classes.modal_container} min-h-0`}>
						<button
							className={classes.icon_close_wrapper}
							onClick={handleClose}
						>
							<CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
						</button>
						<h1 className={classes.modal_title}>Clonar factura</h1>
						{loading &&
							<section className='flex items-center h-[300px]'>
								<Loading loading={loading} />
							</section>
						}
						<section>
							<div className={classes.modal_info_container}>
								{modalClone.fields.length > 0 && !loading && !message.state && (
									<div className={`mt-5 w-full max-h-[50vh]`}>
										{modalClone.fields.map((x) => (
											<div
												key={x.field}
												className={classes.modal_info_element_wrapper}
											>
												<h1 className={classes.modal_info_element_title}>
													{x.field}
												</h1>
												<h1 className={classes.modal_info_element_subtitle}>
													{x.value || '-'}
												</h1>
											</div>
										))}
									</div>
								)}
								{!loading && message.state && (
									<div
										className={`flex-col ${classes.modal_info_loading_container} min-h-0`}
									>
										<p className={`mt-5 ${generals.modal_success_text}`}>
											{message.title}
										</p>
										<p
											className={`mt-7 mb-5 ${generals.modal_success_subtitle}`}
										>
											{message.msg}
										</p>
									</div>
								)}
							</div>

							<div className={classes.modal_wrapper_button}>
								{!loading && !message.state && (
									<button
										className={generals.button_blue}
										onClick={handleCloneFactura}
									>
										Clonar factura
									</button>
								)}
								{!loading && message.state && (
									<button
										onClick={() => {
											setMessage({ state: false, title: '', msg: '' });
											if (message.state !== 'initial') {
												handleClose();
											}
										}}
										className={generals.button_blue}
									>
										{message.state === 'initial' ? 'Continuar' : 'Cerrar'}
									</button>
								)}
							</div>
						</section>
					</div>
				</ClickOutsideReact>
			)}
		</div>
	);
};
export default FacturaModalClone;
