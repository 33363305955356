export const barChartOptionsConsumption = {
	chart: {
		stacked: false,
		toolbar: {
			show: false,
		},
	},
	tooltip: {
		style: {
			fontSize: '14px',
			fontFamily: 'Raleway',
		},
		onDatasetHover: {
			style: {
				fontSize: '14px',
				fontFamily: 'Raleway',
			},
		},
		theme: 'dark',
		y: {
			formatter: function (val) {
				const formattedValue = new Intl.NumberFormat('es-MX', {
					style: 'currency',
					currency: 'MXN',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(val);
				return formattedValue;
			},
		},
	},
	xaxis: {
		show: true,
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '16px',
				fontFamily: 'Raleway',
				fontWeight: '500',
			},
		},
		axisBorder: {
			show: true,
		},
		axisTicks: {
			show: false,
		},
	},
	yaxis: {
		show: false,
		color: 'black',
		labels: {
			show: false,
			style: {
				colors: '#A3AED0',
				fontSize: '16px',
				fontFamily: 'Raleway',
				fontWeight: '500',
			},
		},
	},
	grid: {
		borderColor: 'rgba(163, 174, 208, 0.3)',
		show: true,
		yaxis: {
			lines: {
				show: false,
				opacity: 0.5,
			},
		},
		row: {
			opacity: 0.5,
		},
		xaxis: {
			lines: {
				show: false,
			},
		},
	},
	legend: {
		show: true,
	},
	dataLabels: {
		enabled: false,
	},
	plotOptions: {
		bar: {
			borderRadius: 7,
			columnWidth: '90%',
		},
	},
};
