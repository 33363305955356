import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import generals from '../../../../../css/Generals.module.css';
import { getAllClientStatusById } from '../../../../api/clientStatus';
import { getAllSatDeclarationById } from '../../../../api/satDeclarations';
import NotificationContainer from '../../../../common/NotificationContainer';
import DeclaracionesContainer from './components/DeclaracionesContainer';
import DeclaracionesDashboard from './components/DeclaracionesDashboard';
import DeclaracionesModalClientStatus from './components/DeclaracionesModalClientStatus';
import DeclaracionesModalYear from './components/DeclaracionesModalYear';

const Declaraciones = ({ isSubscriptionActive, setProfileData }) => {
	const [noDeclarations, setNoDeclarations] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState({ state: false, msg: '' });
	const [declarations, setDeclarations] = useState([]);
	const [years, setYears] = useState([]);
	const [refetch, setRefetch] = useState(false);
	const [modalYear, setModalYear] = useState(false);
	const [modalClientStatus, setModalClientStatus] = useState(false);
	const [clientStatus, setClientStatus] = useState(false);
	const [selectedYear, setSelectedYear] = useState(false);

	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	useEffect(() => {
		async function fetchClientStatus() {
			const response = await getAllClientStatusById(TOKEN, USERID);
			if (response.status === 200) {
				const result = await response.json();
				const resultData = result?.data?.data || [];
				if (resultData?.length > 0) {
					const isPendingArray = resultData.filter(
						(x) => x?.estatus === 'pendiente'
					);
					const isApprovedArray = resultData.filter(
						(x) => x?.estatus === 'aprobado' || x?.estatus === 'presentado'
					);

					if (isPendingArray?.length > 0) {
						let pendingClientStatus = isPendingArray[isPendingArray.length - 1];
						setClientStatus(pendingClientStatus);
					} else if (isApprovedArray?.length > 0) {
						let approvedClientStatus =
							isApprovedArray[isApprovedArray.length - 1];
						setClientStatus(approvedClientStatus);
					}
				} else {
					setNoDeclarations(true);
				}
			}
		}

		async function fetchSatDeclarations() {
			const response = await getAllSatDeclarationById(TOKEN, USERID);

			if (response.status === 200) {
				const result = await response.json();
				const resultData = result?.data?.data || [];

				setLoading(false);
				if (resultData?.length > 0) {
					setDeclarations(resultData);
					const pendingYears = resultData.filter(
						(x) =>
							x.currentStatus === 'vigente' || x.currentStatus === 'expirado'
					);
					const completeYears = resultData.filter(
						(x) =>
							x.currentStatus !== 'vigente' && x.currentStatus !== 'expirado'
					);
					const mergedArray = [];
					pendingYears.forEach((y) => {
						const existingYear = mergedArray.find(
							(obj) => obj.declarationYear === y.declarationYear
						);
						if (!existingYear) {
							mergedArray.push(y);
						}
					});
					completeYears.forEach((year) => {
						const existingYear = mergedArray.find(
							(obj) => obj.declarationYear === year.declarationYear
						);
						if (!existingYear) {
							mergedArray.push(year);
						}
					});
					let sortedYears = mergedArray.sort(function (a, b) {
						return b.declarationYear - a.declarationYear;
					});
					setYears(sortedYears);
					setLoading(false);
				} else {
					setLoading(false);
				}
			}
		}

		fetchSatDeclarations();
		fetchClientStatus();
	}, [refetch]);

	return (
		<div className='relative'>
			<div className='w-full flex gap-[20px] items-center'>
				<h2 className='md:text-[59px] text-[32px] md:leading-[81px] leading-[37px] font-bold'>
					Declaraciones
				</h2>
			</div>
			<h3 className={generals.page_subtitle}>
				Módulo para gestión de declaraciones de impuestos
			</h3>
			{!isSubscriptionActive &&
				<NotificationContainer
					title={`Tu suscripción Ciberconta se encuentra vencida.`}
					message={`Para continuar accediendo y gestionando tus declaraciones, por favor actualiza tu suscripción.`}
					ctaLabel={'Administrar mi plan'}
					ctaFunction={() => setProfileData('Mi')} />
			}

			{!noDeclarations && clientStatus && isSubscriptionActive && (
				<DeclaracionesDashboard
					clienStatus={clientStatus}
					setModalClientStatus={setModalClientStatus}
					setRefetch={setRefetch}
				/>
			)}

			{isSubscriptionActive && <DeclaracionesContainer
				loading={loading}
				error={error}
				years={years}
				setModalYear={setModalYear}
				setSelectedYear={setSelectedYear}
			/>}

			{modalYear && isSubscriptionActive && (
				<DeclaracionesModalYear
					setModalYear={setModalYear}
					setRefetch={setRefetch}
					refetch={refetch}
					selectedYear={selectedYear}
					declarations={declarations}
				/>
			)}

			{modalClientStatus && isSubscriptionActive && (
				<DeclaracionesModalClientStatus
					clientStatus={clientStatus}
					setModalClientStatus={setModalClientStatus}
					setRefetch={setRefetch}
				/>
			)}

		</div>
	);
};

export default Declaraciones;
