import React from 'react';
import generals from '../../css/Generals.module.css';
import classes from './NotificationContainer.module.css';

const NotificationContainer = ({ title, message, ctaLabel, ctaFunction }) => {
    return (
        <div className={classes.notification_container}>
            <div className={classes.notification_container_container}>
                <div className={classes.notification_container_message_wrapper}>
                    {title && <h3 className={classes.notification_container_title}>{title}</h3>}
                    {message && <span className={classes.notification_container_message}>{message}</span>}
                    {ctaLabel && ctaFunction &&
                        <button className={generals.button_alternative} onClick={ctaFunction}>
                            {ctaLabel}
                        </button>}
                </div>
            </div>
        </div>
    );
};

export default NotificationContainer;