import { useEffect, useState } from 'react';
import { getAllFacturaById } from '../../../../../api/facturas';
import { getAllSatDeclarationById } from '../../../../../api/satDeclarations';

const useUserData = (TOKEN, USERID, refetch) => {
    const [facturas, setFacturas] = useState([]);
    const [declaraciones, setDeclaraciones] = useState([]);
    const [totals, setTotals] = useState({
        factura: { monthly: [], anual: [] },
        declaraciones: { monthly: [], anual: [] }
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({ state: false, msg: '' });

    const processData = (data, type) => {
        const currentYear = new Date().getFullYear();
        const yearsToInclude = 5;
        const labels = {
            facturas: ['Facturas canceladas', 'Facturas pendientes', 'Facturas pagadas', 'Total facturado'],
            declaraciones: ['Declaraciones pendientes', 'Declaraciones pagadas', 'Total declarado']
        };

        // Initialize structures with names
        const yearlyData = labels[type].map(name => ({
            name,
            data: Array(yearsToInclude).fill(0)
        }));

        const monthlyData = {};
        for (let i = 0; i < yearsToInclude; i++) {
            const year = currentYear - i;
            monthlyData[year] = labels[type].map(name => ({
                name,
                data: Array(12).fill(0)
            }));
        }

        // Process items
        data.forEach(item => {
            const date = new Date(item.createdAt);
            const year = date.getFullYear();
            const month = date.getMonth();
            const yearIndex = (yearsToInclude - 1) - (currentYear - year);

            if (yearIndex >= 0 && yearIndex < yearsToInclude) {
                const amount = type === 'facturas' ? item.total : item.payment;

                if (type === 'facturas') {
                    const isCanceled = item.estatus === 'cancelada' || item.estatus === 'cancelación emitida';
                    if (isCanceled) {
                        // Add to 'Facturas canceladas' (index 0)
                        yearlyData[0].data[yearIndex] += +amount;
                        monthlyData[year][0].data[month] += +amount;
                    } else {
                        const paid = item.pagado === 'pagado';
                        const paidIndex = paid ? 2 : 1;
                        yearlyData[paidIndex].data[yearIndex] += +amount;
                        yearlyData[3].data[yearIndex] += +amount; // Total facturado (index 3)
                        monthlyData[year][paidIndex].data[month] += +amount;
                        monthlyData[year][3].data[month] += +amount;
                    }
                } else {
                    // Handle declaraciones
                    const status = item.currentStatus;
                    const paidIndex = status === 'pagado' ? 1 : 0;
                    yearlyData[2].data[yearIndex] += +amount; // Total declarado
                    yearlyData[paidIndex].data[yearIndex] += +amount;
                    monthlyData[year][2].data[month] += +amount;
                    monthlyData[year][paidIndex].data[month] += +amount;
                }
            }
        });

        // Format numbers
        return {
            anual: yearlyData.map(series => ({
                ...series,
                data: series.data.map(n => parseFloat(n.toFixed(2)))
            })),
            monthly: Object.entries(monthlyData).map(([year, series]) => ({
                year,
                values: series.map(s => ({
                    ...s,
                    data: s.data.map(n => parseFloat(n.toFixed(2)))
                }))
            }))
        };
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [facturasRes, declaracionesRes] = await Promise.all([
                    getAllFacturaById(TOKEN, USERID),
                    getAllSatDeclarationById(TOKEN, USERID)
                ]);

                const [facturasData, declaracionesData] = await Promise.all([
                    facturasRes.json(),
                    declaracionesRes.json()
                ]);

                if (facturasRes.status !== 200 || declaracionesRes.status !== 200) {
                    throw new Error('Error fetching data');
                }

                setFacturas(facturasData?.data?.data || []);
                setDeclaraciones(declaracionesData?.data?.data || []);

                setTotals({
                    factura: processData(facturasData?.data?.data || [], 'facturas'),
                    declaraciones: processData(declaracionesData?.data?.data || [], 'declaraciones')
                });

            } catch (err) {
                setError({
                    state: true,
                    msg: 'Algo salió mal. Favor de volver a intentar más tarde.'
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [TOKEN, USERID, refetch]);

    return {
        facturas,
        declaraciones,
        declaracionesTotals: totals.declaraciones,
        facturaTotals: totals.factura,
        loading,
        error
    };
};

export default useUserData;