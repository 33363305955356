import { default as React, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/layout/navbar/Navbar';
import AvisoPage from './pages/AvisoPage';
import ForgetPassword from './pages/ForgetPassword';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import PreguntasPage from './pages/PreguntasPage';
import ProfileAdminPage from './pages/ProfileAdminPage';
import ProfilePage from './pages/ProfilePage';
import RegisterPage from './pages/RegisterPage';
import ResetPassword from './pages/ResetPassword';
import SuccessPage from './pages/SuccessPage';
import TerminosPage from './pages/TerminosPage';
import { socket } from './socket';

function App() {
	useEffect(() => {
		document.title = 'Ciberconta';

		socket.on('connect', () => console.log(socket.id));
		socket.on('connect_error', () => {
			setTimeout(() => socket.connect(), 5000);
		});
	}, []);

	// Is user logged in
	const { userRole, userRFC } = useSelector((state) => state.clientStatus);
	// Is user subscription active
	const { userSubscription } = useSelector((state) => state.subscriptionStatus);

	const isSubscriptionActive = userSubscription === 'activa';
	const location = useLocation();
	const isProfile = location.pathname === '/profile';

	return (
		<div className='app'>
			{!isProfile && <Navbar />}
			<Routes>
				<Route path='/' element={<HomePage />} />
				<Route path='/precios' element={<HomePage />} />
				<Route path='/login' element={<LoginPage />} />
				<Route path='/signup' element={<RegisterPage />} />
				<Route path='/forget-password' element={<ForgetPassword />} />
				<Route path='/dudas' element={<PreguntasPage />} />
				<Route path='/precios' element={<Navigate to={'/precios'} />} />
				<Route path='/aviso' element={<AvisoPage />} />
				<Route path='/terminos' element={<TerminosPage />} />
				<Route path='/reset-password' element={<ResetPassword />} />
				<Route
					path='/admin'
					element={
						userRole == process.env.REACT_APP_ADMIN ? (
							<ProfileAdminPage />
						) : (
							<Navigate replace to={'/'} />
						)
					}
				/>
				<Route
					path='/success'
					element={userRFC ? <SuccessPage /> : <Navigate replace to={'/'} />}
				/>
				<Route
					path='/profile'
					element={
						userRFC ? (
							<ProfilePage isSubscriptionActive={isSubscriptionActive} />
						) : (
							<Navigate replace to={'/'} />
						)
					}
				/>
				<Route path='*' element={<Navigate replace to={'/'} />} />
			</Routes>
			<div id='sdroot'></div>
		</div>
	);
}

export default App;
