import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationBanner from '../components/common/NotificationBanner';
import Footer from '../components/layout/footer/Footer';
import ProfileLeft from '../components/layout/menu/ProfileLeft';
import ProfileNav from '../components/layout/navbar/ProfileNav';
import Profile from '../components/layout/views/Client/Dashboard/Profile';
import Declaraciones from '../components/layout/views/Client/Declaraciones/Declaraciones';
import Facturas from '../components/layout/views/Client/Facturas/Facturas';
import { Información } from '../components/layout/views/Client/Información/Información';
import Miplan from '../components/layout/views/Client/MiPlan/Miplan';
import Numeros from '../components/layout/views/Client/Numeros/Numeros';
import Preguntas from '../components/layout/views/Client/Preguntas/Preguntas';
import Aviso from '../components/layout/views/Client/Privacidad/Aviso';
import generals from '../css/Generals.module.css';
import { socket } from '../socket';
import { clientStatusActions } from '../store/clientStatus';
import { clientSubscriptionActions } from '../store/clientSubscription';
import { profileActions } from '../store/profile';
import { formatMexicoTz } from '../utils/format';

const ProfilePage = ({ isSubscriptionActive }) => {
	const [notificationShow, setNotificationShow] = useState(true);
	const dispatch = useDispatch();
	const { userprofiledata } = useSelector((state) => state.profile);
	const {
		userSubscription,
		userSubscriptionProduct,
		userSubscriptionUpdate,
		userLastPaymentStatus,
	} = useSelector((state) => state.subscriptionStatus);

	useEffect(() => {
		const getStatus = (event) => {
			// Payment status
			if (event.includes('invoice.payment_failed')) return 'vencida';
			if (event.includes('invoice.payment_succeeded')) return 'activa';
			// Subscription status
			if (event.includes('customer.subscription.deleted')) return 'cancelada';
			if (event.includes('customer.subscription.paused')) return 'pausada';
			if (event.includes('customer.subscription.resumed')) return 'activa';
			if (event.includes('customer.subscription.active')) return 'activa';
			if (event.includes('customer.subscription.past_due')) return 'vencida';
			return null;
		};

		const handlePaymentNotification = (data) => {
			const newStatus = getStatus(data?.event);
			if (!newStatus) return;

			const subscriptionUpdateData = {
				userSubscription: newStatus,
				...(data.subscriptionUpdate && { userSubscriptionUpdate: data.subscriptionUpdate }),
				...(data.lastPaymentStatus && { userLastPaymentStatus: data.lastPaymentStatus }),
				...(data.lastPaymentAmount && { userLastPaymentAmount: data.lastPaymentAmount }),
				...(data.lastPaymentUpdate && { userLastPaymentUpdate: data.lastPaymentUpdate }),
			};

			dispatch(clientSubscriptionActions.setSubscriptionStatus(subscriptionUpdateData));
			dispatch(clientStatusActions.setClientData({ userSubscription: newStatus }));
		};

		socket.on('payment:notification', handlePaymentNotification);
		socket.on('subscription:notification', handlePaymentNotification);

		// Cleanup listeners on unmount
		return () => {
			socket.off('payment:notification', handlePaymentNotification);
			socket.off('subscription:notification', handlePaymentNotification);
		};
	}, [dispatch]);


	if (!userprofiledata) {
		dispatch(profileActions.adduserprofiledata('profile'));
	}

	const setProfileData = (data) => {
		dispatch(profileActions.adduserprofiledata(data));
	};

	const handleBannerClose = () => {
		setNotificationShow(false);
	}

	const handleSubscription = () => {
		setProfileData('Mi');
		setNotificationShow(false);

	}

	return (
		<div>
			<ProfileNav />
			<div className='container pt-[100px]'>
				<div className={generals.page_sidemenu}>
					<ProfileLeft />
				</div>
				<div
					className={`${generals.page_main_container} ${generals.page_main_welcome_container}`}
				>
					{(userprofiledata === 'profile' || !userprofiledata) &&
						<Profile />}
					{userprofiledata === 'Facturas' &&
						<Facturas
							isSubscriptionActive={isSubscriptionActive}
							setProfileData={setProfileData} />}
					{userprofiledata === 'Declaraciones' &&
						<Declaraciones
							isSubscriptionActive={isSubscriptionActive}
							setProfileData={setProfileData} />}
					{userprofiledata === 'Numeros' &&
						<Numeros
							isSubscriptionActive={isSubscriptionActive}
							setProfileData={setProfileData} />}

					{userprofiledata === 'Información' && <Información />}
					{userprofiledata === 'Mi' && <Miplan />}
					{userprofiledata === 'Preguntas' && <Preguntas />}
					{userprofiledata === 'Aviso' && <Aviso title='Aviso de Privacidad' />}
					{userSubscription === 'vencida' &&
						userLastPaymentStatus === 'failure' &&
						notificationShow &&
						<NotificationBanner
							title={`Tu suscripción al plan ${userSubscriptionProduct} se encuentra vencida.`}
							message={`El último intento de pago, con fecha ${formatMexicoTz(userSubscriptionUpdate)}, falló.`}
							onClose={handleBannerClose}
							ctaLabel={'Administrar mi plan'}
							ctaFunction={handleSubscription}
							type={'error'}
						/>}
					{(userSubscription === 'cancelada' ||
						userSubscription === 'pausada') &&
						notificationShow &&
						<NotificationBanner
							title={`Tu suscripción al plan ${userSubscriptionProduct} se encuentra ${userSubscription}.`}
							message={'Reactiva tu plan para seguir disfrutando de los beneficios.'}
							onClose={handleBannerClose}
							ctaLabel={'Administrar mi plan'}
							ctaFunction={handleSubscription}
							type={'error'}
						/>}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ProfilePage;
