import { createSlice } from '@reduxjs/toolkit';

const initialClientStatusState = {
	impuestosPorPagar: 0,
	email: '',
	userName: '',
	userFullName: '',
	userRFC: '',
	userCIEC: '',
	userPhone: '',
	userRole: 0,
	userEfirma: '',
	userStripeId: '',
	userZipCode: '',
	userRegimenFiscal: '',
	userKEYFile: '',
	userCERFile: '',
	userSubscription: '',
	userAnualPlan: '',
};

const clientStatusSlice = createSlice({
	name: 'clientStatus',
	initialState: initialClientStatusState,
	reducers: {
		setImpuestos(state, action) {
			state.impuestosPorPagar = action.payload;
		},
		setClientData(state, action) {
			state.userEmail = action.payload.userEmail ?? state.userEmail;
			state.userName = action.payload.userName ?? state.userName;
			state.userFullName = action.payload.userFullName ?? state.userFullName;
			state.userRFC = action.payload.userRFC ?? state.userRFC;
			state.userCIEC = action.payload.userCIEC ?? state.userCIEC;
			state.userPhone = action.payload.userPhone ?? state.userPhone;
			state.userRole = action.payload.userRole ?? state.userRole;
			state.userEfirma = action.payload.userEfirma ?? state.userEfirma;
			state.userStripeId = action.payload.userStripeId ?? state.userStripeId;
			state.userKEYFile = action.payload.userKEYFile ?? state.userKEYFile;
			state.userCERFile = action.payload.userCERFile ?? state.userCERFile;
			state.userZipCode = action.payload.userZipCode ?? state.userZipCode;
			state.userRegimenFiscal =
				action.payload.userRegimenFiscal ?? state.userRegimenFiscal;
			state.userSubscription =
				action.payload.userSubscription ?? state.userSubscription;
			state.userAnualPlan = action.payload.userAnualPlan ?? state.userAnualPlan;
		},
	},
});

export const clientStatusActions = clientStatusSlice.actions;
export default clientStatusSlice.reducer;
