import { createSlice } from '@reduxjs/toolkit';

const initialclientSubscriptionState = {
	userSubscription: '',
	userSubscriptionProduct: '',
	userSubscriptionUpdate: '',
	userLastPaymentStatus: '',
	userLastPaymentAmount: '',
	userLastPaymentUpdate: '',
	userAnualPlan: '',
};

const clientSubscriptionSlice = createSlice({
	name: 'clientSubscription',
	initialState: initialclientSubscriptionState,
	reducers: {
		setSubscriptionStatus(state, action) {
			state.userSubscription =
				action.payload.userSubscription ?? state.userSubscription;
			state.userSubscriptionProduct =
				action.payload.userSubscriptionProduct ?? state.userSubscriptionProduct;
			state.userSubscriptionUpdate =
				action.payload.userSubscriptionUpdate ?? state.userSubscriptionUpdate;
			state.userLastPaymentStatus =
				action.payload.userLastPaymentStatus ?? state.userLastPaymentStatus;
			state.userLastPaymentAmount =
				action.payload.userLastPaymentAmount ?? state.userLastPaymentAmount;
			state.userLastPaymentUpdate =
				action.payload.userLastPaymentUpdate ?? state.userLastPaymentUpdate;
			state.userAnualPlan = action.payload.userAnualPlan ?? state.userAnualPlan;
		},
	},
});

export const clientSubscriptionActions = clientSubscriptionSlice.actions;
export default clientSubscriptionSlice.reducer;
