import React from 'react';
import generals from '../../css/Generals.module.css';
import classes from './NotificationBanner.module.css';

const NotificationBanner = ({ title, message, type, onClose, ctaLabel, ctaFunction }) => {
    return (
        <div className={type === 'success' ? classes.notification_banner : `${classes.notification_banner} ${classes.notification_error}`}>
            <div className={`container ${classes.notification_banner_container}`}>
                <div className={classes.notification_banner_message_wrapper}>
                    {title && <h3 className={classes.notification_banner_title}>{title}</h3>}
                    {message && <span className={classes.notification_banner_message}>{message}</span>}
                    {ctaLabel && ctaFunction &&
                        <button className={generals.button_alternative} onClick={ctaFunction}>
                            {ctaLabel}
                        </button>}
                </div>
                <div className={classes.notification_banner_button_wrapper}>
                    <button onClick={onClose} className={classes.close_button}>
                        &times;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotificationBanner;