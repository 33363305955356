import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import generals from '../../../../../css/Generals.module.css';
import { formatMexicoTz } from '../../../../../utils/format';
import { getAllFacturaByRFC, getAllFacturas } from '../../../../api/facturas';
import { getAllUsers } from '../../../../api/users';
import FacturasFilters from './components/FacturasFilters';
import FacturasMoreInfo from './components/FacturasMoreInfo';
import Pendings from './components/Pendings';

const Facturas = () => {
	const [moreInfo, setMoreInfo] = useState(false);
	const [refreshFacturas, setRefreshFacturas] = useState(false);
	const [unfilteredUsers, setUnfilteredUsers] = useState([]);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ state: false, message: '' });
	const [totalPendings, setTotalPendings] = useState([]);
	const [totalPresented, setTotalPresented] = useState([]);
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const { filterUserRFC } = useSelector((state) => state.factura);
	// Clear State
	useEffect(() => {
		setError({ state: false, message: '' });

		async function fetchUsers() {
			setLoading(true);
			let resultData = [];
			let fetchedUsers = [];
			if (filterUserRFC && filterUserRFC !== 'Todos los usuarios') {
				resultData = await getAllFacturaByRFC(TOKEN, filterUserRFC);
			} else {
				resultData = await getAllFacturas(TOKEN, setError);
			}

			if (users.length === 0) {
				fetchedUsers = await getAllUsers(TOKEN);
				setUnfilteredUsers(fetchedUsers);
				let usersRFCs = fetchedUsers
					.map((x) => x.rfc)
					.filter((rfc) => rfc !== null);
				setUsers(usersRFCs);
			}

			let facturasArray = [];
			for (let element of resultData) {
				let userNameFactura =
					fetchedUsers.length > 0
						? fetchedUsers
							.filter((user) => user.rfc === element['userRFC'])
							.map((x) => x.name)
						: unfilteredUsers
							.filter((user) => user.rfc === element['userRFC'])
							.map((x) => x.name);
				let userFactura = {
					id: element['idfactura'],
					folioFiscal: element['folioFiscal'] || '-',
					userName: userNameFactura || '-',
					userRFC: element['userRFC'] || '-',
					extranjero: element['extranjero'] || '-',
					pais: element['pais'] || '-',
					idFiscal: element['identificacionfiscal'] || '-',
					nombre: element['nombrecliente'] || '-',
					RFC: element['rfccliente'] || '-',
					email: element['emailcliente'] || '-',
					CP: element['cpcliente'] || '-',
					regimenFiscal: element['regimenfiscal'] || '-',
					formaPago: element['formadepago'] || '-',
					usoCFDI: element['usocfdi'] || '-',
					descripcion: element['descripciondetallada'] || '-',
					observaciones: element['observaciones'] || '-',
					metodoPago: element['metododepago'] || '-',
					total: element['total'] || '-',
					estatus: element['estatus'] || '-',
					fechaSolicitud:
						formatMexicoTz(element['updatedAt'] || element['createdAt']) || '-',
				};
				facturasArray.push(userFactura);
			}
			let facturasPendingArray = facturasArray.filter(
				(x) => x.estatus === 'solicitada' || x.estatus === 'cancelada'
			);
			let facturasPresentedArray = facturasArray.filter(
				(x) => x.estatus !== 'solicitada' && x.estatus !== 'cancelada'
			);

			setTotalPendings(facturasPendingArray);
			setTotalPresented(facturasPresentedArray);
			setLoading(false);
		}

		fetchUsers();
	}, [filterUserRFC, refreshFacturas]);

	return (
		<div className='relative'>
			<div className='w-full flex gap-[20px] items-center'>
				<h2 className='md:text-[59px] text-[32px] md:leading-[81px] leading-[37px] font-bold'>
					Facturas
				</h2>
			</div>
			<h3 className={generals.page_subtitle}>
				Módulo para gestión de facturas{' '}
			</h3>
			<div className='pt-[15px] w-full'>
				<FacturasFilters
					users={users}
					totalPendings={totalPendings}
					totalPresented={totalPresented}
				/>

				<Pendings
					totalPendings={totalPendings}
					totalPresented={totalPresented}
					setMoreInfo={setMoreInfo}
					users={users}
					error={error}
					loading={loading}
				/>
			</div>

			{moreInfo.state && (
				<FacturasMoreInfo
					moreInfo={moreInfo}
					setMoreInfo={setMoreInfo}
					setRefreshFacturas={setRefreshFacturas}
				/>
			)}
		</div>
	);
};

export default Facturas;
