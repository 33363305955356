import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ModalUsersInfo from './components/ModalUsersInfo';
import classes from './css/Users.module.css';

const Generals = ({ users, setSelectedUserId, setUsersMode }) => {
	const timeFrequency = ['Este mes', 'Esta semana', 'Ayer', 'Hoy', 'Histórico'];
	const [currentTimeFrequency, setCurrentTimeFrequency] = useState('Hoy');
	const [generalStats, setGeneralStats] = useState({
		users: [],
		monthlySubscriptions: 0,
		yearlySubscriptions: 0,
		conversionPercentage: 0,
		pendingUsers: [],
	});
	const [moreInfo, setMoreInfo] = useState({
		state: false,
		title: '',
		subtitle: '',
		users: [],
	});
	const [initialDate, setInitialDate] = useState(dayjs());
	const [finalDate, setFinalDate] = useState(dayjs());

	useEffect(() => {
		filterByPeriod('Hoy');
	}, []);

	const getStats = (
		initialDateParameter,
		finalDateParameter,
		usersParameter
	) => {
		if (users) {
			let filteredUsers = [];
			let pendingUsers = [];
			for (let user of usersParameter) {
				if (user.name && user.rfc) {
					let userDate = new Date(user.createdAt);
					if (
						userDate > dayjs(initialDateParameter).toDate() &&
						userDate < dayjs(finalDateParameter).toDate()
					) {
						if (
							!user.CERFile ||
							!user.KEYFile ||
							!user.key ||
							!user.clave_ciec
						) {
							user.pendingSignIn = true;
							pendingUsers.push(user);
						} else {
							user.pendingSignIn = false;
						}
						if (user.subscription !== 'activa') {
							user.pendingPlan = true;
							if (!pendingUsers.includes(user)) {
								pendingUsers.push(user);
							}
						} else {
							user.pendingPlan = false;
						}

						filteredUsers.push(user);
					}
				}
			}
			let monthlySubcriptions = filteredUsers.filter((x) => x.subscription);
			let yearlySubcriptions = filteredUsers.filter((x) => x.anualPlan);
			let conversion = Number(
				((monthlySubcriptions.length + yearlySubcriptions.length) /
					filteredUsers.length) *
				100
			).toFixed(1);

			setGeneralStats({
				users: filteredUsers,
				monthlySubscriptions: monthlySubcriptions,
				yearlySubscriptions: yearlySubcriptions,
				conversionPercentage: conversion,
				pendingUsers: pendingUsers,
			});
		}
	};

	const filterByPeriod = (period) => {
		setCurrentTimeFrequency(period);
		const currentDate = dayjs();
		const startOfDay = dayjs().startOf('day');
		const endOfDay = dayjs().endOf('day');

		switch (period) {
			case 'Histórico': {
				setInitialDate(dayjs('2023-06-08'));
				setFinalDate(currentDate);
				getStats(dayjs('2023-06-08'), currentDate, users);
				break;
			}
			case 'Este mes': {
				const oneMonthAgo = currentDate.subtract(1, 'month');
				setInitialDate(dayjs(oneMonthAgo));
				setFinalDate(currentDate);
				getStats(dayjs(oneMonthAgo), currentDate, users);
				break;
			}
			case 'Hoy': {
				setInitialDate(startOfDay);
				setFinalDate(endOfDay);
				getStats(startOfDay, endOfDay, users);
				break;
			}
			case 'Ayer': {
				const oneDayAgo = currentDate.subtract(1, 'day');
				setInitialDate(oneDayAgo.startOf('day'));
				setFinalDate(oneDayAgo.endOf('day'));
				getStats(oneDayAgo.startOf('day'), oneDayAgo.endOf('day'), users);
				break;
			}
			case 'Esta semana': {
				const oneWeekAgo = currentDate.subtract(1, 'week');
				setInitialDate(dayjs(oneWeekAgo));
				setFinalDate(currentDate);
				getStats(dayjs(oneWeekAgo), currentDate, users);
				break;
			}
		}
	};

	return (
		<>
			<div
				className='py-4 flex flex-col flex-start gap-[20px] items-start'
				key='userRFC'
			>
				<div className='w-full flex justify-between'>
					<div className='flex gap-[40px]'>
						<div>
							<h2 className={classes.users_subtitle}>Fecha inicial</h2>{' '}
							{initialDate && (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										value={initialDate}
										label='Fecha inicial'
										date={initialDate}
										className={`${classes.users_input_box}`}
										sx={{ width: 200 }}
										onChange={(newValue) => {
											setInitialDate(newValue);
											getStats(newValue, finalDate, users);
										}}
									/>
								</LocalizationProvider>
							)}
						</div>
						<div>
							<h2 className={classes.users_subtitle}>Fecha final</h2>{' '}
							{finalDate && (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										value={finalDate}
										label='Fecha final'
										className={`${classes.users_input_box}`}
										sx={{ width: 200 }}
										onChange={(newValue) => {
											setFinalDate(newValue);
											getStats(initialDate, newValue, users);
										}}
									/>
								</LocalizationProvider>
							)}
						</div>
					</div>
					<div>
						<h2 className={classes.users_subtitle}>Filtrar por periodo</h2>{' '}
						<Autocomplete
							disablePortal
							options={timeFrequency}
							value={currentTimeFrequency}
							sx={{ width: 200 }}
							renderInput={(params) => (
								<TextField
									className={`${classes.users_input_box} text-[20px] w-full py-2 px-2`}
									{...params}
								/>
							)}
							onChange={(e, newValue) => {
								filterByPeriod(newValue);
							}}
							blurOnSelect={true}
						/>
					</div>
				</div>
			</div>
			<div className={classes.users_generals_container}>
				<button
					onClick={() =>
						setMoreInfo({
							state: true,
							title: 'Registros',
							subtitle: 'Usuarios registrados en Ciberconta',
							users: generalStats.users,
						})
					}
					className={classes.users_generals_wrapper}
				>
					<h2 className={classes.users_generals_subtitle}>Registros</h2>
					<div className={classes.users_generals_count}>
						<h2>{generalStats.users.length || 0}</h2>
					</div>
				</button>
				<button
					onClick={() =>
						setMoreInfo({
							state: true,
							title: 'Plan FácilFiscal',
							subtitle: 'Usuarios subscritos al plan FácilFiscal',
							users: generalStats.monthlySubscriptions,
						})
					}
					className={classes.users_generals_wrapper}
				>
					<h2 className={classes.users_generals_subtitle}>Plan FácilFiscal</h2>
					<div className={classes.users_generals_count}>
						<h2>{generalStats.monthlySubscriptions.length || 0}</h2>
					</div>
				</button>
				<button
					onClick={() =>
						setMoreInfo({
							state: true,
							title: 'Plan FácilFiscal',
							subtitle: 'Usuarios subscritos al plan RescateFiscal',
							users: generalStats.yearlySubscriptions,
						})
					}
					className={classes.users_generals_wrapper}
				>
					<h2 className={classes.users_generals_subtitle}>
						Plan RescateFiscal
					</h2>
					<div className={classes.users_generals_count}>
						<h2>{generalStats.yearlySubscriptions.length || 0}</h2>
					</div>
				</button>
				<button className={classes.users_generals_wrapper}>
					<h2 className={classes.users_generals_subtitle}>Conversión</h2>
					<div className={classes.users_generals_count}>
						<h2>
							{isNaN(generalStats.conversionPercentage)
								? '0.0'
								: generalStats.conversionPercentage}
							%
						</h2>
					</div>
				</button>
				<button
					onClick={() =>
						setMoreInfo({
							state: true,
							title: 'Usuarios pendientes',
							subtitle:
								'Usuarios pendientes de completar su perfil o contratar plan',
							users: generalStats.pendingUsers,
						})
					}
					className={classes.users_generals_wrapper}
				>
					<h2 className={classes.users_generals_subtitle}>
						Usuarios pendientes
					</h2>
					<div className={classes.users_generals_count}>
						<h2>{generalStats?.pendingUsers?.length || 0}</h2>
					</div>
				</button>
				{moreInfo.state && (
					<ModalUsersInfo
						setMoreInfo={setMoreInfo}
						moreInfo={moreInfo}
						setUsersMode={setUsersMode}
						setSelectedUserId={setSelectedUserId}
					/>
				)}
			</div>
		</>
	);
};

export default Generals;
