import React, { useEffect, useState } from 'react';
import Wallet from '../../../../../../assets/images/wallet.png';
import {
	formatMexicoTz,
	formatTimeStamp,
} from '../../../../../../utils/format';
import styles from '../css/Users.module.css';

const Plan = ({ userData, notifications }) => {
	const [subscription, setSubscription] = useState('');
	const stripe = require('stripe')(process.env.REACT_APP_STRIPE);

	useEffect(() => {
		const fetchCustomerData = async () => {
			const customer = await stripe.customers.retrieve(userData.stripeId, {
				expand: ['subscriptions'],
			});
			const subscriptions = customer.subscriptions.data;

			if (subscriptions.length > 0) {
				for (let item of subscriptions) {
					if (
						item.plan.product === process.env.REACT_APP_STRIPE_FACILFISCAL
					) {
						setSubscription(item);
					}
				}
			}
		};
		if (userData?.stripeId) fetchCustomerData();
	}, [notifications, userData]);


	return (
		<div className={`${styles.plan_item}`}>
			<div className={`${styles.plan_item_icon_wrapper}`}>
				<img className={`${styles.plan_item_icon_img}`} src={Wallet} alt='' />
			</div>
			<div className={`${styles.plan_item_body_wrapper}`}>
				<div className={`${styles.plan_item_body_header}`}>
					<p className={`${styles.plan_item_body_title}`}>{userData.name}</p>
				</div>
				<div className={`${styles.plan_item_body_section}`}>
					<p className={`${styles.plan_item_body_text}`}>
						Suscripción: {userData.subscriptionProduct || subscription?.plan?.nickname || 'N/A'}
					</p>
					{subscription?.start_date &&
						<p className={`${styles.plan_item_body_pay}`}>
							Fecha de inicio: {formatTimeStamp(subscription.start_date)}
						</p>
					}
					{userData.subscription &&
						<p className={`${styles.plan_item_body_pay}`}>
							Fecha de última actualización: {formatTimeStamp(userData.subscriptionUpdate)}
						</p>
					}

				</div>
				<div className={`${styles.plan_item_body_section}`}>
					<p className={`${styles.plan_item_body_text}`}>
						Análisis y regularización fiscal:{' '}
						{userData.anualPlan ? ' Contratado' : ' No contratado'}
					</p>
					{userData.anualPlan && (
						<p className={`${styles.plan_item_body_pay}`}>
							Años contratados: {userData.anualPlan}
						</p>
					)}
				</div>
				{userData.lastPaymentAmount && userData.lastPaymentUpdate && (
					<div className={`${styles.plan_item_body_section} mt-8`}>
						<p className={`${styles.plan_item_body_pay}`}>
							Último Pago: ${userData.lastPaymentAmount}
						</p>
						<p className={`${styles.plan_item_body_pay}`}>
							Fecha de pago: {formatMexicoTz(userData.lastPaymentUpdate)}
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default Plan;
