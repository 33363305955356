// Chakra imports
import {
	Box
} from '@chakra-ui/react';

import BarChart from '../../../../../common/horizon/BarChart';
import Card from '../../../../../common/horizon/Card';
import classes from '../css/Numeros.module.css';
import {
	barChartOptionsConsumption
} from '../variables/charts';

export default function Stats({ data, currentPeriod, currentYear, currentType, yearsArr }) {
	const hasNonZeroDataStrict = (dataArray) =>
		dataArray.some(({ data }) => data.some(n => Number(n) !== 0));
	const graphIsNotEmpty = hasNonZeroDataStrict(data);

	const monthArr = [
		'Ene',
		'Feb',
		'Mar',
		'Abr',
		'May',
		'Jun',
		'Jul',
		'Ago',
		'Sep',
		'Oct',
		'Nov',
		'Dic',
	]
	const colorsArr = currentType === 'Facturas' ?
		['#db6363', 'rgba(87, 87, 87, 0.55)', '#9ccc26', '#4285f4'] :
		['rgba(87, 87, 87, 0.55)', '#9ccc26', '#4285f4'];

	const options = {
		...barChartOptionsConsumption,
		xaxis: {
			categories: currentPeriod === 'Mensual' ? monthArr : yearsArr
		},
		colors: colorsArr,
		fill: {
			type: 'solid',
			colors: colorsArr,
		},
	};

	return (
		<Card alignItems='center' flexDirection='column' w='100%'>
			<Box className={classes.table_container} w='100%'>
				<h1 className={classes.table_title}>{`${currentType} ${currentPeriod} ${currentYear}`}</h1>
				{graphIsNotEmpty && <BarChart
					key={`${currentPeriod}_${currentYear}_${currentType}`}
					chartData={data}
					chartOptions={options}
				/>}
				{!graphIsNotEmpty && currentType &&
					<div className={classes.table_subtitle_wrapper}>
						<h2 className={classes.table_subtitle}>{`Lo sentimos. Por el momento no cuentas con ${currentType.toLowerCase()} registradas en este periodo.`} </h2>
					</div>
				}
			</Box>
		</Card >
	);
}
