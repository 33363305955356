import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllClientStatusFiltered } from '../../../../api/clientStatus';
import { getAllSatDeclarationsFiltered } from '../../../../api/satDeclarations';
import Loading from '../../../../common/Loading';
import classes from './css/Presentar.module.css';
import DetailHeaderPendings from './DetailHeaderPendings';
import DetailPendings from './DetailPendings';
import { spanishMonthOrder } from './utils/utils';

const Pendings = ({
	setModal,
	setMoreInfo,
	users,
	setTotalPendings,
	setTotalPresented,
}) => {
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const { filterUserRFC, pending, presented } = useSelector(
		(state) => state.satDeclaration
	);
	const [currentSortingCategory, setCurrentSortingCategory] = useState('');
	const [declarationPendings, setDeclarationPendings] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ state: false, msg: '' });

	const sortResults = (sortingOptions) => {
		const getMonth = (declarationPending) => {
			return (
				declarationPending.mesRegistro || declarationPending.declarationMonth
			);
		};
		const getYear = (declarationPending) => {
			return (
				declarationPending['añoRegistro'] || declarationPending.declarationYear
			);
		};
		switch (sortingOptions.category) {
			case 'Tipo': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? declarationPendings
							.slice()
							.sort((a, b) => b.type.localeCompare(a.type))
						: declarationPendings
							.slice()
							.sort((a, b) => a.type.localeCompare(b.type));

				setDeclarationPendings(sortedArray);
				break;
			}
			case 'Periodo': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? declarationPendings
							.slice()
							.sort(
								(a, b) =>
									new Date(getYear(b), spanishMonthOrder[getMonth(b)]) -
									new Date(getYear(a), spanishMonthOrder[getMonth(a)])
							)
						: declarationPendings
							.slice()
							.sort(
								(a, b) =>
									new Date(getYear(a), spanishMonthOrder[getMonth(a)]) -
									new Date(getYear(b), spanishMonthOrder[getMonth(b)])
							);

				setDeclarationPendings(sortedArray);
				break;
			}
			case 'Aprobado/Solicitado':
			case 'Presentada': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? declarationPendings
							.slice()
							.sort(
								(a, b) =>
									new Date(a.fechaAprobado || a.createdAt) -
									new Date(b.fechaAprobado || b.createdAt)
							)
						: declarationPendings
							.slice()
							.sort(
								(a, b) =>
									new Date(b.fechaAprobado || b.createdAt) -
									new Date(a.fechaAprobado || a.createdAt)
							);

				setDeclarationPendings(sortedArray);
				break;
			}
			case 'RFC': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? declarationPendings
							.slice()
							.sort((a, b) => b.userRFC.localeCompare(a.userRFC))
						: declarationPendings
							.slice()
							.sort((a, b) => a.userRFC.localeCompare(b.userRFC));

				setDeclarationPendings(sortedArray);
				break;
			}
			case 'Estatus': {
				const sortedArray =
					'ascending' === sortingOptions.order
						? declarationPendings
							.slice()
							.sort((a, b) =>
								(b.estatus || b.currentStatus).localeCompare(
									a.estatus || a.currentStatus
								)
							)
						: declarationPendings
							.slice()
							.sort((a, b) =>
								(a.estatus || a.currentStatus).localeCompare(
									b.estatus || b.currentStatus
								)
							);

				setDeclarationPendings(sortedArray);
				break;
			}
		}
	};

	useEffect(() => {
		const fetchUserClientStatus = async () => {
			setLoading(true);
			setCurrentSortingCategory('');
			try {
				const responseSatDeclarations = await getAllSatDeclarationsFiltered(
					TOKEN,
					filterUserRFC,
					pending,
					presented
				);
				const resultSatDeclarations = await responseSatDeclarations.json();
				const resultDataSatDeclarations = resultSatDeclarations?.data?.data;
				for (let element of resultDataSatDeclarations) {
					let user = users.find((user) => element.userId === user.id);
					element.type = 'satDeclaration';
					element.userSubscription = user.subscription;
					element.userAnualPlan = user.anualPlan;
				}

				if (pending) {
					const responseClientStatus = await getAllClientStatusFiltered(
						TOKEN,
						filterUserRFC
					);
					const resultClientStatus = await responseClientStatus.json();
					const resultDataClientStatus = resultClientStatus?.data?.data;
					for (let element of resultDataClientStatus) {
						let user = users.find((user) => element.clientId === user.id);
						element.type = 'clientStatus';
						element.userRFC = user.label;
						element.userSubscription = user.subscription;
						element.userAnualPlan = user.anualPlan;
					}
					let newResults = [
						...resultDataClientStatus,
						...resultDataSatDeclarations,
					];
					setDeclarationPendings(newResults);
					setTotalPendings(newResults.length);
					setTotalPresented('');
					setLoading(false);
				} else {
					setDeclarationPendings(resultDataSatDeclarations);
					setTotalPendings('');
					setTotalPresented(resultDataSatDeclarations.length);
					setLoading(false);
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
				setError({
					state: true,
					msg: 'Lo sentimos, parece ser que ocurrió un error.',
				});
			}
		};
		if (users.length > 0) {
			fetchUserClientStatus();
		}
	}, [filterUserRFC, pending, presented, users]);

	return (
		<div>
			<DetailHeaderPendings
				sortResults={sortResults}
				currentSortingCategory={currentSortingCategory}
				setCurrentSortingCategory={setCurrentSortingCategory}
				isPendingDeclarations={pending}
			/>
			{!loading &&
				!error.state &&
				declarationPendings.length === 0 &&
				users.length > 0 && (
					<section className={classes.loading_container}>
						<h1>No se han encontrado registros.</h1>
					</section>
				)}
			{!loading && error.state && (
				<section className={classes.loading_container}>
					<h1>{error.msg}</h1>
				</section>
			)}
			{loading && !error.state && (
				<section className={classes.loading_container}>
					<Loading loading={loading} />
				</section>
			)}

			{!loading && !error.state && declarationPendings.length > 0 && (
				<div className={classes.details_container}>
					{declarationPendings.map((declarationPending) => (
						<DetailPendings
							key={
								(declarationPending.userId || declarationPending.clientId) +
								declarationPending.type +
								(declarationPending.mesRegistro ||
									declarationPending.declarationMonth) +
								(declarationPending['añoRegistro'] ||
									declarationPending.declarationYear)
							}
							userData={declarationPending}
							setMoreInfo={setMoreInfo}
							setModal={setModal}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default Pendings;
