import { MenuItem, Select } from '@mui/material';
import { default as React, useState } from 'react';
import { useSelector } from 'react-redux';
import generals from '../../../../../css/Generals.module.css';
import Loading from '../../../../common/Loading';
import NotificationContainer from '../../../../common/NotificationContainer';
import Stats from './components/Stats';
import classes from './css/Numeros.module.css';
import useUserData from './hooks/useUserData';

const Numeros = ({ isSubscriptionActive, setProfileData }) => {
    const currentYearVal = new Date().getFullYear();
    const yearsArr = Array.from({ length: 5 }, (_, i) => currentYearVal - (4 - i));
    const [refetch, setRefetch] = useState(false);

    const [currentPeriod, setCurrentPeriod] = useState('Anual');
    const [currentType, setCurrentType] = useState('Facturas');
    const [currentYear, setCurrentYear] = useState('Histórico');

    const periodOptions = ['Anual', 'Mensual'];
    const typeOptions = ['Facturas', 'Declaraciones'];
    const yearOptions = [...yearsArr, 'Histórico'];

    const TOKEN = useSelector((state) => state.auth.tokenId);
    const USERID = useSelector((state) => state.auth.userId);

    const { declaracionesTotals, facturaTotals, loading, error } = useUserData(TOKEN, USERID, refetch);
    const [chartData, setChartData] = useState(false)

    return (
        <div className='relative'>
            <div className='w-full flex gap-[20px] items-center'>
                <h2 className='md:text-[59px] text-[32px] md:leading-[81px] leading-[37px] font-bold'>
                    Mis Números
                </h2>

            </div>
            <h3 className={generals.page_subtitle}>
                Tu resumen financiero: estadísticas de facturas y declaraciones en un solo lugar
            </h3>
            {!isSubscriptionActive &&
                <NotificationContainer
                    title={`Tu suscripción Ciberconta se encuentra vencida.`}
                    message={`Para continuar accediendo y gestionando tu dashboard informativo, por favor actualiza tu suscripción.`}
                    ctaLabel={'Administrar mi plan'}
                    ctaFunction={() => setProfileData('Mi')} />
            }
            {loading && !error.state && isSubscriptionActive && (
                <section className={classes.loading_container}>
                    <Loading loading={loading} />
                </section>
            )}
            {!loading && error.state && isSubscriptionActive && (
                <section className={classes.loading_container}>
                    <h1>{error.msg}</h1>
                </section>
            )}
            {!loading && !error.state && isSubscriptionActive &&
                <div className='my-10 w-full flex justify-between'>
                    <div className='flex-col'>
                        <h2 className={`${generals.filter_subtitle} mb-0`}>Tipo</h2>
                        <Select
                            id='tipo'
                            value={currentType}
                            sx={{ width: 200, height: 45 }}
                            className={`${generals.select_input} text-[20px] w-full py-2 px-2`}
                            onChange={(e) => {
                                const newType = e.target.value;
                                const isAnnual = currentPeriod === 'Anual';
                                const totals = newType === 'Declaraciones' ? declaracionesTotals : facturaTotals;

                                setCurrentType(newType);

                                const data = isAnnual
                                    ? totals.anual
                                    : totals.monthly.find(x => x.year === currentYear.toString())?.values || [];

                                setChartData(data);
                            }}
                        >
                            {typeOptions?.map((x) => (
                                <MenuItem key={x} value={x}>
                                    {x}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className='flex items-center gap-[20px]'>
                        <div className='flex-col'>
                            <h2 className={`${generals.filter_subtitle} mb-0`}>Periodo</h2>
                            <Select
                                id='periodo'
                                value={currentPeriod}
                                sx={{ width: 200, height: 45 }}
                                className={`${generals.select_input} text-[20px] w-full py-2 px-2`}
                                onChange={(e) => {
                                    const newPeriod = e.target.value;
                                    const isAnnual = newPeriod === 'Anual';
                                    const totals = currentType === 'Declaraciones' ? declaracionesTotals : facturaTotals;

                                    setCurrentPeriod(newPeriod);
                                    setCurrentYear(isAnnual ? 'Histórico' : currentYearVal);

                                    const data = isAnnual
                                        ? totals.anual
                                        : totals.monthly.find(x => x.year === currentYearVal.toString())?.values || [];

                                    setChartData(data);
                                }}
                            >
                                {periodOptions?.map((x) => (
                                    <MenuItem key={x} value={x}>
                                        {x}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className='flex-col'>
                            <h2 className={`${generals.filter_subtitle} mb-0`}>Año</h2>
                            <Select
                                id='anual'
                                value={currentYear}
                                sx={{ width: 200, height: 45 }}
                                className={`${generals.select_input} text-[20px] w-full py-2 px-2`}
                                onChange={(e) => {
                                    const newYear = e.target.value;
                                    const isHistorico = newYear === 'Histórico';
                                    const totals = currentType === 'Declaraciones' ? declaracionesTotals : facturaTotals;

                                    setCurrentYear(newYear);
                                    setCurrentPeriod(isHistorico ? 'Anual' : 'Mensual');

                                    const data = isHistorico
                                        ? totals.anual
                                        : totals.monthly.find(x => x.year === newYear.toString())?.values || [];

                                    setChartData(data);
                                }}
                            >
                                {yearOptions?.reverse().map((x) => (
                                    <MenuItem key={x} value={x}>
                                        {x}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>}
            {!loading && !error.state &&
                isSubscriptionActive &&
                facturaTotals?.anual?.length > 0 &&
                declaracionesTotals?.anual?.length > 0 &&
                <Stats
                    data={chartData || facturaTotals.anual}
                    currentType={currentType}
                    currentYear={currentYear}
                    currentPeriod={currentPeriod}
                    yearsArr={yearsArr}
                />}
        </div>
    );
};

export default Numeros;
