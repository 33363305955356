import { removeCommas } from '../../utils/format';

const processConcepto = async (concepto, clone = false) => {
	if (!concepto) return null;

	if (typeof concepto === 'string') {
		try {
			concepto = JSON.parse(concepto);
		} catch (error) {
			console.error('Invalid JSON string:', error);
			return null;
		}
	}

	const processedConcepto = {
		...concepto,
		objetoDeImpuesto: clone
			? concepto?.objetoDeImpuesto
			: concepto?.objetoDeImpuesto?.label,
		codigoObjetoDeImpuesto: clone
			? concepto?.codigoObjetoDeImpuesto
			: concepto?.objetoDeImpuesto?.code,
		unidad: clone ? concepto?.unidad : concepto?.unidad?.label,
		codigoUnidad: clone ? concepto?.codigoUnidad : concepto?.unidad?.code,
	};

	const numericKeys = ['cantidad', 'precioUnitario', 'importe'];

	Object.keys(processedConcepto).forEach((key) => {
		if (
			numericKeys.includes(key) &&
			typeof processedConcepto[key] === 'string'
		) {
			processedConcepto[key] = processedConcepto[key].replace(/,/g, '');
		}
	});
	return JSON.stringify(processedConcepto);
};

export const getAllFacturas = async (TOKEN, setError) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/factura/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 100,
						sort: { updatedAt: -1 },
					},
					isCountOnly: false,
				}),
			}
		);
		const result = await response.json();
		const resultData = result?.data?.data;
		return resultData;
	} catch (error) {
		console.error('Error:', error);
		setError({
			state: true,
			message: 'Algo salió mal. Favor de volver a intentar.',
		});
	}
};

export const getAllFacturaById = async (TOKEN, USERID) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/factura/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 100,
						sort: { updatedAt: -1 },
						where: { userId: USERID },
					},
					isCountOnly: false,
				}),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const getAllFacturaContactsById = async (TOKEN, USERID) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/facturaContact/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 10,
						sort: { id: -1 },
						where: { userId: USERID },
					},
					isCountOnly: false,
				}),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const addFactura = async (TOKEN, factura, foreigner) => {
	try {
		let totalString = removeCommas(factura.total);
		let formattedConcepto = await processConcepto(factura?.concepto);
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/factura/create',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					userId: factura.userId,
					userRFC: factura.userRFC,
					extranjero: foreigner ? 'extranjero' : 'local',
					identificacionfiscal: factura.idfiscal,
					pais: factura.country,
					nombrecliente: factura.clientName,
					cpcliente: factura.clientZipCode,
					emailcliente: factura.clientEmail,
					descripciondetallada: factura.description,
					observaciones: factura.observaciones,
					rfccliente: factura.clientRFC,
					regimenfiscal: factura.regimenFiscal,
					usocfdi: factura.CFDI,
					formadepago: factura.paymentForm,
					metododepago: factura.paymentMethod,
					moneda: factura.currency,
					codigodefactura: '',
					estatus: 'solicitada',
					subtotal: Number(totalString),
					concepto: formattedConcepto,
				}),
			}
		);
		const result = await response.json();
		return result;
	} catch (error) {
		console.error(error);
	}
};

export const cloneFactura = async (TOKEN, USERID, factura) => {
	try {
		let totalString = removeCommas(factura?.subtotal || factura?.total);
		let formattedConcepto = await processConcepto(factura?.concepto, true);
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/factura/create',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					userId: USERID,
					userRFC: factura.userRFC,
					extranjero: factura.extranjero ? factura.extranjero : 'local',
					identificacionfiscal: factura.identificacionfiscal,
					pais: factura.pais ? factura.pais : 'México',
					nombrecliente: factura.nombrecliente,
					cpcliente: factura.cpcliente,
					emailcliente: factura.emailcliente,
					descripciondetallada: factura.descripciondetallada,
					observaciones: factura.observaciones,
					rfccliente: factura.rfccliente,
					regimenfiscal: factura.regimenfiscal,
					usocfdi: factura.usocfdi,
					formadepago: factura.formadepago,
					metododepago: factura.metododepago,
					moneda: factura.moneda,
					codigodefactura: '',
					estatus: 'solicitada',
					subtotal: Number(totalString),
					concepto: formattedConcepto,
				}),
			}
		);
		return response;
	} catch (error) {
		console.error(error);
	}
};

export const editFactura = async (TOKEN, factura, foreigner) => {
	try {
		let totalString = removeCommas(factura.total);
		let formattedConcepto = await processConcepto(factura?.concepto);
		const response = await fetch(
			process.env.REACT_APP_API_URL +
				'client/api/v1/factura/partial-update/' +
				factura.idfactura,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					userId: factura.userId,
					userRFC: factura.userRFC,
					extranjero: foreigner ? 'extranjero' : 'local',
					identificacionfiscal: factura.idfiscal,
					pais: factura.country,
					nombrecliente: factura.clientName,
					cpcliente: factura.clientZipCode,
					emailcliente: factura.clientEmail,
					descripciondetallada: factura.description,
					observaciones: factura.observaciones,
					rfccliente: factura.clientRFC,
					regimenfiscal: factura.regimenFiscal,
					usocfdi: factura.CFDI,
					formadepago: factura.paymentForm,
					metododepago: factura.paymentMethod,
					moneda: factura.currency,
					codigodefactura: '',
					estatus: 'solicitada',
					subtotal: Number(totalString),
					concepto: formattedConcepto,
				}),
			}
		);
		const result = await response.json();
		return result;
	} catch (error) {
		console.error(error);
	}
};

export const addFacturaContact = async (TOKEN, facturaContact) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/facturaContact/create',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					userId: facturaContact.userId,
					userRFC: facturaContact.userRFC,
					extranjero: facturaContact.foreigner ? 'extranjero' : 'local',
					identificacionfiscal: facturaContact.idfiscal,
					pais: facturaContact.foreigner ? facturaContact.country : 'México',
					nombrecliente: facturaContact.clientName,
					cpcliente: facturaContact.clientZipCode,
					emailcliente: facturaContact.clientEmail,
					rfccliente: facturaContact.clientRFC,
					createdAt: new Date(),
				}),
			}
		);
		return response;
	} catch (error) {
		console.error(error);
	}
};

export const getAllFacturaByRFC = async (TOKEN, userRFC) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL + 'client/api/v1/factura/list',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					options: {
						paginate: 100,
						sort: { updatedAt: -1 },
						where: { userRFC: userRFC },
					},
					isCountOnly: false,
				}),
			}
		);
		const result = await response.json();
		const resultData = result?.data?.data;
		return resultData;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const partialUpdateFactura = async (TOKEN, FACTURAID, queryUpdate) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL +
				'client/api/v1/factura/partial-update/' +
				FACTURAID,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify(queryUpdate),
			}
		);
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};

export const deleteFacturaContact = async (TOKEN, ID) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL +
				'client/api/v1/facturaContact/delete/' +
				ID,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					isWarning: false,
				}),
			}
		);

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const cancelFactura = async (TOKEN, ID, motive) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_API_URL +
				'client/api/v1/factura/partial-update/' +
				ID,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					estatus: 'cancelada',
					motivoDeCancelacion: motive,
				}),
			}
		);

		return response;
	} catch (error) {
		console.error(error);
	}
};
