import CloseIcon from '@mui/icons-material/Close';
import $ from 'jquery';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import generals from '../../../../../../css/Generals.module.css';
import { partialUpdateFactura } from '../../../../../api/facturas';
import ClickOutsideReact from '../../../../../common/Clickoutside';
import Loading from '../../../../../common/Loading';
import classes from '../css/FacturaModal.module.css';

const FacturaModalConfirm = ({ setModalConfirm, modalConfirm, setRefetch }) => {

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        state: 'initial',
        title:
            `Esta factura está marcada como ${modalConfirm.factura.pagado === 'pagado' ? 'pagada' : 'no pagada'}. ¿Deseas cambiar su estatus?`,
        msg: `Podrás consultar información más a detalle de tus facturas en la sección Mis Números. Accede a ella desde tu menú izquierdo.`,
    });
    const TOKEN = useSelector((state) => state.auth.tokenId);

    const handleClose = () => {
        $('body').css('overflow', 'auto');
        setModalConfirm(false);
        setMessage({
            state: false,
            msg: '',
        });
    };

    const handleMarkAsPaidFactura = async () => {
        setLoading(true);
        try {
            let queryUpdate = { pagado: modalConfirm?.factura?.pagado === 'pagado' ? 'no pagado' : 'pagado' }
            const response = await partialUpdateFactura(TOKEN, modalConfirm?.factura?.idfactura, queryUpdate);
            const result = await response.json();
            if (response.status === 200 && result.status === 'SUCCESS') {
                setRefetch((prevState) => !prevState);
                setMessage({
                    state: 'success',
                    title: '¡Cambio de estatus exitoso!',
                    msg: `La factura ha sido marcada como ${modalConfirm.factura.pagado !== 'pagado' ? 'pagada' : 'pagada'}.`,
                });
            } else {
                setMessage({
                    state: 'error',
                    msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
                });
            }
        } catch (error) {
            console.error(error);
            setMessage({
                state: 'error',
                msg: 'Algo salió mal. Favor de volver a intentar más tarde.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.modal_overlay}>
            {modalConfirm && (
                <ClickOutsideReact onClickOutside={handleClose}>
                    <div className={`${classes.modal_container} min-h-0`}>
                        <button
                            className={classes.icon_close_wrapper}
                            onClick={handleClose}
                        >
                            <CloseIcon sx={{ fontSize: 25, color: '#b9b9b9' }} />
                        </button>
                        <h1 className={`mt-10 ${classes.modal_title}`}>Cambiar estatus de factura</h1>
                        {loading &&
                            <section className='flex items-center h-[300px]'>
                                <Loading loading={loading} />
                            </section>
                        }
                        <section>
                            <div className={classes.modal_info_container}>
                                {!loading && message.state && (
                                    <div className={`flex-col ${classes.modal_info_loading_container} min-h-0`}>
                                        <p className={`mt-5 ${generals.modal_success_text}`}>
                                            {message.title}
                                        </p>
                                        <p className={`mb-5 ${generals.modal_success_subtitle}`}>
                                            {message.msg}
                                        </p>
                                    </div>
                                )}
                            </div>
                            {!loading &&
                                <div className={classes.modal_wrapper_button}>
                                    {message.state === 'initial' && (
                                        <button
                                            className={generals.button_blue}
                                            onClick={handleMarkAsPaidFactura}
                                        >
                                            {modalConfirm.factura.pagado === 'pagado' ? 'Marcar como no pagada' : 'Marcar como pagada'}
                                        </button>
                                    )}
                                    <button
                                        onClick={handleClose}
                                        className={generals.button_white}
                                    >
                                        Cerrar
                                    </button>
                                </div>}
                        </section>
                    </div>
                </ClickOutsideReact>
            )}
        </div>
    );
};
export default FacturaModalConfirm;
